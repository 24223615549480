<template>
  <div id="Case">
    <h2>安徽省芜湖市湾沚区</h2>
    <!-- <p>Wanzhi County Education Bureau Smart Education Management Platform</p> -->
    <div class="case-box">
      <p>
        以SaaS云服务部署、“云+端”方式为区域及学校、家庭搭建湾沚区“一站式”智慧区域云平台。提供区域“智慧办公”、“智慧门户”、“智慧教学”、“智慧教研”、“智慧家联”等五大应用场景。大数据汇总收集学校使用情况，致力提升区域管理扁平高效，让区域及学校网站个性张扬，局校之间沟通更加便捷，管理学校更加高效。
      </p>
      <img src="../../../assets/images/partnersCase/cases/case12.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Case',
  metaInfo: {
    title: '伙伴与客户',
    meta: [
      { name: 'keywords', content: '晓羊集团' },
      { name: 'description', content: '' },
    ],
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {
    //模拟触发滚动1像素，为了触发在首屏应该展示的动画（numbers动画），也能顺便返回页面顶部
    window.scrollTo(0, 1)
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
%Case {
  width: 100%;
  padding-top: 180px;
  position: relative;
  background: $--color-white;
  @include px2vw('padding-bottom', 140);
  // @include px2vw('padding-right', 240);
  text-align: center;
  h2 {
    font-size: 32px;
  }
  > p {
    font-size: 24px;
    color: #333333;
    position: relative;
    &::after {
      content: ' ';
      width: 66px;
      height: 3px;
      display: inline-block;
      position: absolute;
      left: 48%;
      margin-top: 45px;
      background: $--color-primary;
    }
  }
  .case-box {
    margin: 60px auto 0;
    width: 90%;
    max-width: 1320px;

    p {
      line-height: 32px;
      font-size: 18px;
      text-align: justify;
      color: #666;
    }
    img {
      width: 60%;
      margin-top: 80px;
    }
  }
}

.view-pc > #Case {
  @extend %Case;
  @media screen and (max-width: 1200px) {
    .case-box {
      width: 90%;
      img {
        width: 100%;
      }
    }
  }
}

.view-mobile > #Case {
  @extend %Case;
  h2 {
    font-size: 22px;
  }
  .case-box {
    width: 90%;
    margin-top: 30px !important;
    p {
      font-size: 14px;
      line-height: 28px;
      margin: 30px 0;
    }
    img {
      margin-top: 0 !important;
    }
  }
  margin-top: 44px;
  padding-top: 15px;
  > p {
    font-size: 14px;
    &::after {
      left: 42%;
      margin-top: 35px;
    }
  }
  .case-box {
    margin-top: 80px;
    img {
      width: 100%;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    > p {
      &::after {
        left: 45%;
        margin-top: 35px;
      }
    }
  }
}
</style>
